import React from 'react';
import * as PropTypes from "prop-types";
import { useLocation } from "@reach/router";

import FormVerificationWrapper from '../../components/FormVerificationWrapper/FormVerificationWrapper.js';
import CompanyLeadForm from '../../components/CompanyLeadForm/CompanyLeadForm.js';

import { sendCompanyLeadDataForm } from '../../models/form';

import {
  FORM_FIELDS,
  verificationFormType
} from './constants.js';

const SignUpCompanyLeadForm = ({ textSubmit, canBeFixed = "false" }) => {

  const { state } = useLocation();

  const signUpCompanyLeadFormOpened = state?.signUpCompanyLeadFormOpened ?? false;

  return (
    <FormVerificationWrapper
      canBeFixed={canBeFixed}
      form={CompanyLeadForm}
      formFieldValues={FORM_FIELDS}
      openFormButtonTitle={textSubmit}
      reachGoalTitle="fos_v3_send"
      verificationType={verificationFormType}
      signUpFormOpened={signUpCompanyLeadFormOpened}
      submitForm={sendCompanyLeadDataForm}
    />
  );
};

SignUpCompanyLeadForm.propTypes = {
  textSubmit: PropTypes.string,
  canBeFixed: PropTypes.oneOf(['true', 'false']),
};

export default SignUpCompanyLeadForm;
